import { CHANGE_COMMENT } from '../actionTypes';

const initialState = {
  comments: {},
};

export default function(state = initialState, action) {
  switch (action.type) {
    case CHANGE_COMMENT: {
      const { content } = action.payload;
      return {
        ...state,
        comments: {
          ...state.comments,
          [content.countMain]: {
            ...state.comments[content.countMain],
            [content.id]: content.comment,
          },
        },
      };
    }
    default:
      return state;
  }
}

import {
  ADD_QUESTIONS,
  ADD_CSEL,
  CHANGE_CSEL_SELECTED,
  CHANGE_EGYEB,
} from '../actionTypes';

const initialState = {
  questions: {},
  cselTervs: {},
};

export default function(state = initialState, action) {
  switch (action.type) {
    case ADD_QUESTIONS: {
      const { content } = action.payload;
      return {
        ...state,
        questions: {
          ...content.data,
        },
      };
    }
    case ADD_CSEL: {
      const { content } = action.payload;
      return {
        ...state,
        cselTervs: {
          ...state.cselTervs,
          [content.countMain]: content.data,
        },
      };
    }
    case CHANGE_CSEL_SELECTED: {
      const { content } = action.payload;
      const arr = state.cselTervs[content.countMain];
      const modArr = arr.map((x, i) => {
        if (i !== content.i) {
          return x;
        }
        return {
          ...x,
          isSelected: !arr[content.i].isSelected,
        };
      });
      const mainSel = modArr
        .filter(x => x.cselCountMain === content.cselCountMain && !x.isMain)
        .some(x => x.isSelected);
      const modArr2 = modArr.map(x => {
        if (x.cselCountMain !== content.cselCountMain || !x.isMain) {
          return x;
        }
        return {
          ...x,
          isSelected: mainSel,
        };
      });
      return {
        ...state,
        cselTervs: {
          ...state.cselTervs,
          [content.countMain]: modArr2,
        },
      };
    }
    case CHANGE_EGYEB: {
      const { content } = action.payload;
      let arr = state.cselTervs[content.countMain];
      if (!arr.filter(x => x.egyeb === true).length) {
        arr.push({ name: content.egyeb, egyeb: true, isSelected: true });
      } else {
        arr = arr.map(x => {
          if (!x.egyeb) {
            return x;
          }
          return { ...x, name: content.egyeb };
        });
      }
      return {
        ...state,
        cselTervs: {
          ...state.cselTervs,
          [content.countMain]: arr,
        },
      };
    }
    default:
      return state;
  }
}

import { CHANGE_ACTIVE } from '../actionTypes';

const initialState = {
  active: {},
};

export default function(state = initialState, action) {
  switch (action.type) {
    case CHANGE_ACTIVE: {
      const { content } = action.payload;
      const newState = {
        ...state.active[content.countMain],
        [content.id]: content.num,
      };
      newState[0] = null;
      const mainAct = Object.values(newState).some(x => x === 1 || x === 2)
        ? 1
        : 0;
      return {
        ...state,
        active: {
          ...state.active,
          [content.countMain]: {
            ...state.active[content.countMain],
            [content.id]: content.num,
            0: mainAct,
          },
        },
      };
    }
    default:
      return state;
  }
}

export const CHANGE_ACTIVE = 'CHANGE_ACTIVE';
export const CHANGE_COMMENT = 'CHANGE_COMMENT';
export const ADD_IMAGES = 'ADD_IMAGES';
export const DEL_IMAGE = 'DEL_IMAGE';
export const ADD_QUESTIONS = 'ADD_QUESTIONS';
export const ADD_CSEL = 'ADD_CSEL';
export const CHANGE_CSEL_SELECTED = 'CHANGE_CSEL_SELECTED';
export const CHANGE_EGYEB = 'CHANGE_EGYEB';
export const CHANGE_CEGNEV = 'CHANGE_CEGNEV';
export const CHANGE_TELEPHELY = 'CHANGE_TELEPHELY';
export const CHANGE_CIM = 'CHANGE_CIM';
export const CHANGE_KESZITETTE = 'CHANGE_KESZITETTE';
export const CHANGE_DATE = 'CHANGE_DATE';
export const CHANGE_UGYFELKAT = 'CHANGE_UGYFELKAT';
export const CHANGE_HIDE = 'CHANGE_HIDE';

import {
  CHANGE_ACTIVE,
  CHANGE_COMMENT,
  ADD_IMAGES,
  DEL_IMAGE,
  ADD_QUESTIONS,
  ADD_CSEL,
  CHANGE_CSEL_SELECTED,
  CHANGE_EGYEB,
  CHANGE_CEGNEV,
  CHANGE_TELEPHELY,
  CHANGE_CIM,
  CHANGE_KESZITETTE,
  CHANGE_UGYFELKAT,
  CHANGE_DATE,
  CHANGE_HIDE,
} from './actionTypes';

export const changeActive = content => ({
  type: CHANGE_ACTIVE,
  payload: {
    content,
  },
});

export const changeComment = content => ({
  type: CHANGE_COMMENT,
  payload: {
    content,
  },
});

export const addImages = content => ({
  type: ADD_IMAGES,
  payload: {
    content,
  },
});

export const addQuestions = content => ({
  type: ADD_QUESTIONS,
  payload: {
    content,
  },
});

export const addCsel = content => ({
  type: ADD_CSEL,
  payload: {
    content,
  },
});

export const changeCselSelected = content => ({
  type: CHANGE_CSEL_SELECTED,
  payload: {
    content,
  },
});

export const changeEgyeb = content => ({
  type: CHANGE_EGYEB,
  payload: {
    content,
  },
});

export const delImgs = content => ({
  type: DEL_IMAGE,
  payload: {
    content,
  },
});

export const changeCegnev = content => ({
  type: CHANGE_CEGNEV,
  payload: {
    content,
  },
});

export const changeTelephely = content => ({
  type: CHANGE_TELEPHELY,
  payload: {
    content,
  },
});

export const changeCim = content => ({
  type: CHANGE_CIM,
  payload: {
    content,
  },
});

export const changeKeszitette = content => ({
  type: CHANGE_KESZITETTE,
  payload: {
    content,
  },
});

export const changeDate = content => ({
  type: CHANGE_DATE,
  payload: {
    content,
  },
});

export const changeUgyfelKat = content => ({
  type: CHANGE_UGYFELKAT,
  payload: {
    content,
  },
});

export const changeHide = content => ({
  type: CHANGE_HIDE,
  payload: {
    content,
  },
});

import {
  CHANGE_CEGNEV,
  CHANGE_TELEPHELY,
  CHANGE_CIM,
  CHANGE_KESZITETTE,
  CHANGE_UGYFELKAT,
  CHANGE_DATE,
} from '../actionTypes';

const initialState = {
  cegnev: '',
  telephely: '',
  cim: '',
  keszitette: '',
  ugyfelKat: '',
  date: new Date().toISOString().slice(0, 10),
};

export default function(state = initialState, action) {
  switch (action.type) {
    case CHANGE_CEGNEV: {
      const { content } = action.payload;
      return {
        ...state,
        cegnev: content.cegnev,
      };
    }
    case CHANGE_TELEPHELY: {
      const { content } = action.payload;
      return {
        ...state,
        telephely: content.telephely,
      };
    }
    case CHANGE_CIM: {
      const { content } = action.payload;
      return {
        ...state,
        cim: content.cim,
      };
    }
    case CHANGE_KESZITETTE: {
      const { content } = action.payload;
      return {
        ...state,
        keszitette: content.keszitette,
      };
    }
    case CHANGE_UGYFELKAT: {
      const { content } = action.payload;
      return {
        ...state,
        ugyfelKat: content.ugyfelKat,
      };
    }
    case CHANGE_DATE: {
      const { content } = action.payload;
      return {
        ...state,
        date: content.date,
      };
    }
    default:
      return state;
  }
}

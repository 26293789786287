import { ADD_IMAGES, DEL_IMAGE } from '../actionTypes';

const initialState = {
  imgsArrs: {},
};

export default function(state = initialState, action) {
  switch (action.type) {
    case ADD_IMAGES: {
      const { content } = action.payload;
      let retArr;
      if (
        state.imgsArrs[content.countMain] &&
        state.imgsArrs[content.countMain][content.id]
      ) {
        retArr = [
          ...state.imgsArrs[content.countMain][content.id],
          ...content.imgsrcArr,
        ];
      } else {
        retArr = content.imgsrcArr;
      }
      return {
        ...state,
        imgsArrs: {
          ...state.imgsArrs,
          [content.countMain]: {
            ...state.imgsArrs[content.countMain],
            [content.id]: retArr,
          },
        },
      };
    }
    case DEL_IMAGE: {
      const { content } = action.payload;
      return {
        ...state,
        imgsArrs: {
          ...state.imgsArrs,
          [content.countMain]: {
            ...state.imgsArrs[content.countMain],
            [content.id]: [
              ...state.imgsArrs[content.countMain][content.id].slice(
                0,
                content.index
              ),
              ...state.imgsArrs[content.countMain][content.id].slice(
                content.index + 1
              ),
            ],
          },
        },
      };
    }
    default:
      return state;
  }
}

import { CHANGE_HIDE } from '../actionTypes';

const initialState = {
  mainHide: {},
};

export default function(state = initialState, action) {
  switch (action.type) {
    case CHANGE_HIDE: {
      const { content } = action.payload;
      return {
        ...state,
        mainHide: {
          ...state.mainHide,
          [content.mainId]: !state.mainHide[content.mainId],
        },
      };
    }
    default:
      return state;
  }
}
